import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/archer',
    name: 'archer',
    component: () => import('../views/locations/Archer.vue')
  },
  {
    path: '/broomfield',
    name: 'broomfield',
    component: () => import('../views/locations/Broomfield.vue')
  },
  {
    path: '/foothills',
    name: 'foothills',
    component: () => import('../views/locations/Foothills.vue')
  },
  {
    path: '/afflerbach',
    name: 'afflerbach',
    component: () => import('../views/locations/Afflerbach.vue')
  },
  {
    path: '/ginger',
    name: 'ginger',
    component: () => import('../views/locations/GingerBaker.vue')
  },
  {
    path: '/stjohn',
    name: 'stjohn',
    component: () => import('../views/locations/StJohn.vue')
  },
  {
    path: '/westminster',
    name: 'westminster',
    component: () => import('../views/locations/Westminster.vue')
  },
  {
    path: '/iceevents',
    name: 'iceevents',
    component: () => import('../views/locations/IceEvents.vue')
  },
  {
    path: '/islandgrove',
    name: 'islandgrove',
    component: () => import('../views/locations/IslandGrove.vue')
  },
  {
    path: '/riverdale',
    name: 'riverdale',
    component: () => import('../views/locations/Riverdale.vue')
  },
  {
    path: '/windsor',
    name: 'windsor',
    component: () => import('../views/locations/Windsor.vue')
  },
  {
    path: '/lyons',
    name: 'lyons',
    component: () => import('../views/locations/Lyons.vue'),
  },
  {
    path: '/boulder',
    name: 'boulder',
    component: () => import('../views/locations/Boulder.vue'),
  },
  {
    path: '/highlands',
    name: 'highlands',
    component: () => import('../views/locations/Highlands.vue'),
  },
  {
    path: '/flagbeanies',
    name: 'flagbeanies',
    component: () => import('../views/products/FlagBeanies.vue')
  },
  {
    path: '/flagheadbands',
    name: 'flagheadbands',
    component: () => import('../views/products/FlagHeadbands.vue')
  },
  {
    path: '/doll',
    name: 'doll',
    component: () => import('../views/products/Doll.vue')
  },
  {
    path: '/dollgirl',
    name: 'dollgirl',
    component: () => import('../views/products/Dollgirl.vue')
  },
  {
    path: '/headbands',
    name: 'headbands',
    component: () => import('../views/products/Headbands.vue')
  },
  {
    path: '/headwraps',
    name: 'headwraps',
    component: () => import('../views/products/Headwraps.vue')
  },
  {
    path: '/glitzy',
    name: 'glitzy',
    component: () => import('../views/products/Glitzy.vue')
  },
  {
  path: '/tweed',
  name: 'tweed',
  component: () => import('../views/products/Tweed.vue')
  },
  {
    path: '/rasta',
    name: 'rasta',
    component: () => import('../views/products/Rasta.vue')
  },
  {
    path: '/pride',
    name: 'pride',
    component: () => import('../views/products/Pride.vue')
  },
  {
    path: '/hunter',
    name: 'hunter',
    component: () => import('../views/products/Hunter.vue')
  },
  {
    path: '/camo',
    name: 'camo',
    component: () => import('../views/products/Camo.vue')
  },
  {
    path: '/crown',
    name: 'crown',
    component: () => import('../views/products/Crown.vue')
  },
  {
    path: '/headwraps',
    name: 'headwraps',
    component: () => import('../views/products/Headwraps.vue')
  },
  {
    path: '/blends',
    name: 'blends',
    component: () => import('../views/products/Blends.vue')
  },
  {
    path: '/headbands',
    name: 'headbands',
    component: () => import('../views/products/Headbands.vue')
  },
  {
    path: '/signature',
    name: 'signature',
    component: () => import('../views/products/Signature.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
