<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/FlagFlat4Small.jpg')"
          padding
          contain
          height="200"
          class="mt-2"
          />
      </v-col>
      <v-col class="mb-1">
        <h3 class="display-1  mb-1">Welcome to EverSoftKnits</h3>
        <p class="subheading font-weight-regular">
          Please visit and support our local vendors
          <br>during the following show dates
        </p>
      </v-col>
      <v-col class="mb-1" cols="12">
        <v-row justify="center">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" >
                  2024
                  </th>
                  <th class="text-center">
                    Venue
                  </th>
                  <th class="text-center">
                  Location
                  </th>
                  <th class="text-center">
                    Directions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="show in shows" :key="show.name">
                  <td>{{ show.date }}</td>
                  <td>{{ show.venue }}</td>
                  <td>{{ show.location }}</td>
                  <td><v-btn text router :to="show.route"><v-icon>mdi-map</v-icon></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        shows: [
          // {
          //   date: 'January 13th',
          //   venue: 'Winter Farmers Market',
          //   location: 'Fort Collins',
          //   route: '/foothills'
          // },
          // {
          //   date: 'January 28th',
          //   venue: 'Winter Farmers Market',
          //   location: 'Fort Collins',
          //   route: '/foothills'
          // },
          // {
          //   date: 'February 10th',
          //   venue: 'Winter Farmers Market',
          //   location: 'Fort Collins',
          //   route: '/foothills'
          // },
          // {
          //   date: 'February 24th',
          //   venue: 'Winter Farmers Market',
          //   location: 'Fort Collins',
          //   route: '/foothills'
          // },
          // {
          //   date: 'March 11th',
          //   venue: 'Winter Farmers Market',
          //   location: 'Fort Collins',
          //   route: '/foothills'
          // },
          // {
          //   date: 'March 25th',
          //   venue: 'Winter Farmers Market',
          //   location: 'Fort Collins',
          //   route: '/foothills'
          // },
          {
            date: 'September 14',
            venue: 'Heros Fall Craft Fair',
            location: 'Cheyenne Ice and Events Center',
            route: '/iceevents'
          },
          {
            date: 'September 28-29',
            venue: 'Fall Craft Show',
            location: 'Highlands Ranch Community Center',
            route: '/highlands'
          },
          {
            date: 'October 12',
            venue: 'Craft and Vendor Fair',
            location: 'Cheyenne - Afferbach Elementary',
            route: '/afflerbach'
          },
          {
            date: 'October 13',
            venue: 'Makers Market',
            location: 'Fort Collins - Ginger Baker',
            route: '/ginger'
          },
          {
            date: 'October 19-20',
            venue: 'Mollie McGees',
            location: 'Boulder County Fairgrounds',
            route: '/boulder'
          },
          {
            date: 'October 26-27',
            venue: 'St.Johns Holiday Fair',
            location: 'Loveland - St. John Parish',
            route: '/stjohn'
          },
          {
            date: 'November 2',
            venue: 'Holiday Craft Fair',
            location: 'Westminster - The MAC',
            route: '/westminster'
          },
          {
            date: 'November 9-10',
            venue: 'Country Christmas',
            location: 'Brighton - Adams County Fairgrounds',
            route: '/riverdale'
          },
          {
            date: 'November 16',
            venue: 'Winter Farmers Market',
            location: 'Fort Collins - Foothills Mall',
            route: '/foothills'
          },
          {
            date: 'November 23-24',
            venue: 'Christmas in Windsor',
            location: 'Windsor Rec Center',
            route: '/windsor'
          },
          {
            date: 'November 30',
            venue: 'Winter Farmers Market',
            location: 'Fort Collins - Foothills Mall',
            route: '/foothills'
          },
          {
            date: 'December 1st',
            venue: 'Holiday Market',
            location: 'Broomfield High School',
            route: '/broomfield'
          },
          {
            date: 'December 7-8',
            venue: 'Community Holiday Gift Fair',
            location: 'Lyons Elementary School',
            route: '/lyons'
          },
          {
            date: 'December 14-15',
            venue: 'Country Christmas',
            location: 'Cheyenne - Archer Events Center',
            route: '/archer'
          },
          {
            date: 'December 14, 21, 30',
            venue: 'Winter Farmers Market',
            location: 'Fort Collins - Foothills Mall',
            route: '/foothills'
          },
        ],
      }
    },
  }
</script>

